import 'material-design-icons-iconfont/dist/material-design-icons.css'

<template>
  
  <div class="pa-3" id="tester">

    <uploadingDialog v-bind:uploadingDialog = "this.uploadingDialog"></uploadingDialog>
    <mySnackBar      v-bind:MySnackBar      = "this.MySnackBar"></mySnackBar>

    <v-container>
     <v-row class="pt-2 mb-2 d-flex justify-space-between align-baseline">
           
       <div>Annual Liquid Inspection</div>
         
        <div>   
        <v-btn  v-if = "this.$router.currentRoute.path != '/'"  @click="formReviewUpload" class="mx-2" fab dark small color="red darken-3">
           <v-icon dark>mdi-file-pdf</v-icon>
        </v-btn>
      
        <v-btn  v-if = "this.$router.currentRoute.path != '/'" @click="formReview" class="mx-2" fab medium color="success">
          <v-icon dark>mdi-presentation-play</v-icon>
        </v-btn>
        
        </div>

     </v-row>
    </v-container>

    <v-row class="mt-0" justify="center">

    <v-expansion-panels class="expansion_panels">

      <!-- Instructions - // remove this section 9/3/2021
        <v-expansion-panel class="expansion_panel mb-1"> 
          <v-expansion-panel-header>Enhanced Test Instructions</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="header text-justify">
            Annual Inspection Form for Liquid Installations
          </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      -->
      
      <!-- customerComponent -->
        <customer v-bind:customer = "customer" />
      <!-- customerComponent -->

      <!-- Section 1 - autogas unit -->
      <v-expansion-panel class="expansion_pane mb-1"> 
        <v-expansion-panel-header>Section 1 - Autogas Unit Type</v-expansion-panel-header>
        <v-expansion-panel-content>

          <v-container>
          <v-row v-for="(item, index) in section_1" :key="index" dense>

                    <v-col cols="12" sm="4">
                       <v-checkbox dense v-model= "item.value" :label="item.label"></v-checkbox>
                    </v-col>
                   
                    <v-col cols="6" sm="4">
                        <v-text-field    v-model= "item.meterMfr" :disabled = "!item.value" outlined dense label="Meter Mfr / Type"></v-text-field>
                    </v-col>  
                   
                    <v-col cols="6" sm="4">
                        <v-text-field  v-model= "item.serialNumber" :disabled = "!item.value" outlined dense label="Serial Number"></v-text-field>
                    </v-col>
           
          </v-row>
          </v-container>
       
       
        </v-expansion-panel-content>
      </v-expansion-panel>
     
        <!-- Section 2 - Hose details -->
        <v-expansion-panel class="expansion_pane mb-1"> 
          <v-expansion-panel-header>Section 2 - Hose Details</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row dense v-for="(item, index) in section_2" :key="index">

                    <v-col cols="12">
                           <v-checkbox dense v-model="item.enabled" :label=" 'Hose No: ' + (index +1)"></v-checkbox>
                    </v-col>
                   
                    <v-col cols="12" sm="3">
                           <v-text-field  outlined dense v-model="item.serial"        :disabled = "!item.enabled" label="Serial"></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" sm="3">
                           <v-text-field  outlined dense v-model="item.manufacturer"  :disabled = "!item.enabled" label="Manufacturer"></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" sm="2">
                            <v-text-field  outlined dense v-model="item.length"      :disabled = "!item.enabled"  label="Length"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="2">
                            <v-text-field  outlined dense v-model="item.size"        :disabled = "!item.enabled"  label="Size"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="2">
                            <DateSelector 
                                          caption        = "Date" 
                                          v-bind:enabled = "item.enabled"
                                          v-bind:curDate = "item.date"
                                           @updated="(e) => {item.date = e}"
                            ></DateSelector>
                    </v-col>
                 
                </v-row>
              </v-container>
            </v-expansion-panel-content>
         </v-expansion-panel>
        <!-- Section 2 - Hose details -->


      <!-- Section 3 - Vessel BS 5500-->
      <v-expansion-panel class="expansion_pane mb-1"> 
        <v-expansion-panel-header>Section 3 - Vessel(s)</v-expansion-panel-header>
          <v-expansion-panel-content>

            <v-container>
              <v-row dense v-for="(item, index) in section_3" :key="index">

                    <v-col cols="12">
                          <v-checkbox dense v-model= "item.value" :label="item.label"></v-checkbox>
                    </v-col>
                   
                    <v-col cols="12" sm="3">
                           <v-text-field  v-model= "item.serialNumber" :disabled = "!item.value" outlined dense label="Serial Number"></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" sm="3">
                           <v-text-field  v-model= "item.size" :disabled = "!item.value" outlined dense label="Size"></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" sm="3">
                            <DateSelector 
                                          caption        = "Test Date" 
                                          v-bind:enabled = "item.value"
                                          v-bind:curDate = "item.testDate"
                                           @updated="(e) => {item.testDate = e}"
                            ></DateSelector>
                    </v-col>
                    
                    <v-col cols="12" sm="3">
                          <v-text-field  v-model= "item.colour"  :disabled = "!item.value" outlined dense label="Colour"></v-text-field>
                    </v-col>
                  
              </v-row>
            </v-container>
        
        </v-expansion-panel-content>
      </v-expansion-panel> 

      <!-- Section 4 - Pump and Associated Equipment-->
      <v-expansion-panel class="dl_expansion_pane mb-1"> 
        <v-expansion-panel-header>Section 4 - Pump and Associated Equipment</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container>
            <v-row>

              <v-col cols="12" sm="6">
                  <v-text-field  dense outlined  v-model = "section_4['pumpTypeAndSize']"   label   = "Pump Type and Size"></v-text-field>
                  <v-text-field  dense outlined  v-model = "section_4['pumpSerialNumber']"  label   = "Pump Serial Number"></v-text-field>
                  <v-text-field  dense outlined  v-model = "section_4['byPassType']"        label   = "Bypass Type"></v-text-field>
                  <v-text-field  dense outlined  v-model = "section_4['pumpDifferential']"  label   = "Pump Differential"></v-text-field>
              </v-col>
 
              <v-col cols="12" sm="6">
                  <v-text-field  dense outlined  v-model = "section_4['motorMake']"         label   = "Motor Make"></v-text-field>
                  <v-text-field  dense outlined  v-model = "section_4['kwRating']"          label   = "Kw Rating"></v-text-field>
                  <v-text-field  dense outlined  v-model = "section_4['motorSerialNumber']" label   = "Motor Serial Number"></v-text-field>
                   <DateSelector 
                                       caption        = "Date of Manufacture" 
                                       v-bind:enabled = "true"
                                       v-bind:curDate = "section_4['dateOfManufacture']"
                                       @updated="(e) => {section_4['dateOfManufacture'] = e}"
                   ></DateSelector>
              </v-col>

            </v-row>
       </v-container>
       
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Section 5 - Associated Itemns-->
      <v-expansion-panel class="expansion_pane mb-1"> 
        <v-expansion-panel-header>Section 5 - Associated Items</v-expansion-panel-header>
        <v-expansion-panel-content>
   
          <v-container>
            <v-row v-for="(item, index) in section_5" :key="index" dense>

                    <v-col cols="12">
                       <v-checkbox dense v-model = "item.value" :label="item.label"></v-checkbox>
                    </v-col>
                   
                    <v-col cols="6">
                       <v-text-field  :disabled = "!item.value" outlined dense v-model="item.itemType" label="Item Type"></v-text-field>
                    </v-col>  
                   
                    <v-col cols="6">
                        <v-text-field  :disabled = "!item.value" outlined dense v-model="item.itemComments" label="Comments"></v-text-field>
                    </v-col>
           
          </v-row>
        </v-container>
       

         
       
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Section 6 - Yes/No Questions -->
      <v-expansion-panel class="dl_expansion_pane mb-1"> 
        <v-expansion-panel-header  class="dl_expansion_panel_header">Section 6 - Yes/No Questions</v-expansion-panel-header>
        <v-expansion-panel-content class="dl_expansion_panel_content">

         <v-container>
           <v-simple-table >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left"></th>
                  <th class="text-left">YES / NO</th>
                </tr>
              </thead>
              <tbody>

                <tr v-for="(item, index) in section_6" :key="index" >
                   <td>{{ item.criteria }}</td>
                   <td>
                   <toggle-button
                      :value        = "item.value"
                      :labels       = "{checked: 'YES', unchecked: 'NO'}"
                      :switch-color = "{checked: '#25EF02', unchecked: 'red'}"
                      color         = "#82C7EB"
                      :width        = "60"
                      :height       = "28"
                      v-model       = "item.value"
                      @change       = "(v) => item.value"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
           </v-simple-table>
         </v-container>

        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Section 7 - Comments -->
      <v-expansion-panel class="dl_expansion_pane mb-1"> 
        <v-expansion-panel-header  class="dl_expansion_panel_header">Section 7 - Comments</v-expansion-panel-header>
        <v-expansion-panel-content class="dl_expansion_panel_content">
      
        <v-container>
           <v-textarea class="pa-2"
           clearable
           clear-icon="mdi-close-circle"
           name="input-7-1"
           filled
           label="Comments"
           auto-grow
           v-model       = "section_7.comments"
           :value        = "section_7.comments"
            @change       = "(v) => section_7.comments"
           ></v-textarea>
        </v-container>
       
       
        </v-expansion-panel-content>
      </v-expansion-panel>


            <!-- last one for space-->
                <v-expansion-panel class="expansion_panel mb-2"> 
                </v-expansion-panel>
           <!-- last one for space-->


     <!--
       <v-expansion-panel id = "spacer" class="dl_expansion_panel">
       </v-expansion-panel>
       -->

    </v-expansion-panels>

    </v-row>

  </div>

</template>

<script>

import {store} from '../../main.js'
import Localbase from 'localbase'

export default {
    name       : "annualInspection",
    formName   : "annualInspection",

    destroyed () {

          store.consoleLog( "annualInspection destroyed");
          let saveObject           =  this.generateSaveObject();
          const customer           =  this.customer['ACCOUNT'];
          const documentKey        =  this.$options.formName;
          this.saveDocument( customer, documentKey, saveObject);

          // store.saveFormObject("annualInspection", saveObject)
    },
    created    (){ 
  
          store.consoleLog( "annualInspection created");
      
          this.customer  = JSON.parse( localStorage.getItem("equalResponseCustomer") );
          this.salesman  = JSON.parse( localStorage.getItem("equalResponseSalesman") );
          this.engineer  = JSON.parse( localStorage.getItem("equalResponseEngineer") );

          //////////////////////////////////////////////
          //  reload the last form if any             //
          //////////////////////////////////////////////
          const customer           = this.customer['ACCOUNT'];
          const documentKey        = this.$options.formName;
          this.retrieveDocument( customer, documentKey);

          /*
          var lastestObject = store.retrieveFormObject("annualInspection");
          if ( lastestObject != null ) {
             this.section_1      = lastestObject.formReviewData.section_1; 
             this.section_2      = lastestObject.formReviewData.section_2;
             this.section_3      = lastestObject.formReviewData.section_3;
             this.section_4      = lastestObject.formReviewData.section_4;
             this.section_5      = lastestObject.formReviewData.section_5;
             this.section_6      = lastestObject.formReviewData.section_6;
             this.section_7      = lastestObject.formReviewData.section_7;
          }
          */
 
    },
    methods   : {

              saveDocument( customer, documentKey, documentObject ){
                    let db     = new Localbase( this.$dbName );
                    db.collection( customer ).doc(documentKey).set( documentObject )
                    .then(response => {
                      console.log( response)
                    })
                    .catch(error => {
                      console.log( error)
                    })
              },

              retrieveDocument( customer, documentKey ){

                    let db = new Localbase(  this.$dbName );
                    db.collection(customer).doc(documentKey).get()
                    .then(  lastestObject => {
                      if (  lastestObject != null ) {
                        this.section_1      = lastestObject.formReviewData.section_1;
                        this.section_2      = lastestObject.formReviewData.section_2;
                        this.section_3      = lastestObject.formReviewData.section_3;
                        this.section_4      = lastestObject.formReviewData.section_4;
                        this.section_5      = lastestObject.formReviewData.section_5;
                        this.section_6      = lastestObject.formReviewData.section_6;
                        this.section_7      = lastestObject.formReviewData.section_7;
                      }
                    })
                    .catch( error => {
                      console.log( error)
                    });
              },

                showSnackBar( message = "You need to review the form first..."){
                    this.MySnackBar.text          =  message;
                    this.MySnackBar.showImage     = "icon";
                    this.MySnackBar.show          = true;
                    setTimeout( () => { this.MySnackBar.show  = false }, 1000)
                },
                showUploadingDialog( message = "Uploading PDF...") {
                         this.uploadingDialog.message    = message;
                         this.uploadingDialog.showDialog = true;
                },
                closeUploadingDialog( message = "PDF Uploaded...") {
                          this.uploadingDialog.message    = message;
                          setTimeout( () => {
                                    this.uploadingDialog.message    = "";
                                    this.uploadingDialog.showDialog = false;
                                 }, 2000)
                },
                generateSaveObject() {

                                  let saveObject  =  { 
                                          "formReviewName"   : "annualInspection",
                                          "formReviewURL"    : this.$restServer.annualInspectionURL,
                                          "formReviewData"   :  {  
                                                                             "requestedResponse"   : "pdf",
                                                                             "customer"            : this.customer,
                                                                             "salesman"            : this.salesman,
                                                                             "engineer"            : this.engineer,
                                                                             "section_1"           : this.section_1,
                                                                             "section_2"           : this.section_2,
                                                                             "section_3"           : this.section_3,
                                                                             "section_4"           : this.section_4,
                                                                             "section_5"           : this.section_5,
                                                                             "section_6"           : this.section_6,
                                                                             "section_7"           : this.section_7
                                                                        } 
                                  };
                                   return  saveObject;
                }, 
                generateLoggingObject() {
                     return  { 
                               "engineer"    : this.engineer['ENGINEER_ID'].toLowerCase(),
                               "account"     : this.customer['ACCOUNT'].toLowerCase(), 
                               "formName"    : this.formName 
                             };
                }, 
                formReviewUpload() {

            
                       const url                       =  this.$restServer.uploadPDFToDropBoxURL;
                       const formReviewReponse         = store.retrieveFormReviewResponse();
                       if ( formReviewReponse  ) {

                            let   self        = this;
                            formReviewReponse['loggingObject']     = this.generateLoggingObject();
                            this.showUploadingDialog();

                            this.$http.post( url, formReviewReponse ) 
                            .then(  (response) => {
                                store.resetFormReviewResponse();
                                self.closeUploadingDialog();
                            })
                            .catch( (e) => { 
                                   self.closeUploadingDialog(e.message);
                                   localStorage.removeItem("equalResponsePdfReviewObject");
                                   console.log(e);
                            });
  
                      } else {
                          this.showSnackBar("You need to review the form first...");
                      }
                   
                },
                formReview(e){
                        try {
                
                            var saveObject  =  this.generateSaveObject();
                            store.saveFormObject("annualInspection", saveObject)
                            this.$router.push( { name:"FormReview", "params" : saveObject } );

                        } catch (e) {
                          alert(e);
                        }
                },
    },
    data() {
    return {
        MySnackBar        :  { "show" : false, "text" : "Default Text", "showImage" : "icon"},
        uploadingDialog   :  { "showDialog" : false, "message": "" },
        formName     : "annualInspection",
        customer     : {},
        salesman     : {},
        engineer     : {},
        section_1    : [
                    {"label" : "Unmetered / FLT",       "id": "unMetered" ,         "value"     : false,  "meterMfr" :"",  "serialNumber":""},
                    {"label" : "Commercial Metered",    "id": "commercialMetered" , "value"     : false,  "meterMfr" :"",  "serialNumber":""},
                    {"label" : "Retail Metered",        "id": "retailMeterd" ,      "value"     : false,  "meterMfr" :"",  "serialNumber":""},
                    {"label" : "Other Metered:",        "id": "otherMetered" ,      "value"     : false,  "meterMfr" :"",  "serialNumber":""},
        
        ], 
        section_2 : [
                       { 
                         "enabled"                  : false,
                         "serial"                   : "",
                         "size"                     : "", 
                         "manufacturer"             : "",
                         "length"                   : "", 
                         "date"                     : ""
                       },{
                         
                         "enabled"                  : false,
                         "serial"                   : "",
                         "size"                     : "", 
                         "manufacturer"             : "",
                         "length"                   : "", 
                         "date"                     : ""
  
                       },{
                         "enabled"                  : false,
                         "serial"                   : "",
                         "size"                     : "", 
                         "manufacturer"             : "",
                         "length"                   : "", 
                         "date"                     : ""
                       },{ 
                         "enabled"                  : false,
                         "serial"                   : "",
                         "size"                     : "", 
                         "manufacturer"             : "",
                         "length"                   : "", 
                         "date"                     : ""
                       },{
                         "enabled"                  : false,
                         "serial"                   : "",
                         "size"                     : "", 
                         "manufacturer"             : "",
                         "length"                   : "", 
                         "date"                     : ""
                       },{ 
                           "enabled"                  : false,
                           "serial"                   : "",
                           "size"                     : "", 
                           "manufacturer"             : "",
                           "length"                   : "", 
                           "date"                     : ""
                       },{
                           "enabled"                  : false,
                           "serial"                   : "",
                           "size"                     : "", 
                           "manufacturer"             : "",
                           "length"                   : "", 
                           "date"                     : ""
                       }
        ],
        section_3 : [
                        { "label" : "Vessel 1", "serialNumber":"", "size":"", "testDate":"", "colour":""},
                        { "label" : "Vessel 2", "serialNumber":"", "size":"", "testDate":"", "colour":""},
                        { "label" : "Vessel 3", "serialNumber":"", "size":"", "testDate":"", "colour":""},
        ],
        section_4 : {
                         "pumpTypeAndSize"      : "",
                         "pumpSerialNumber"     : "",
                         "byPassType"           : "",
                         "pumpDifferential"     : "",
                         "motorMake"            : "",
                         "kwRating"             : "",
                         "motorSerialNumber"    : "",
                         "dateOfManufacture"    : "" 
        },
        section_5 : [  
                    { "value":"", "label" : "Grease Breakaway - Check Operation",   "itemType" : "",  "itemComments" : "" },
                    { "value":"", "label" : "Dispensing Gun",                       "itemType" : "",  "itemComments" : "" },
                    { "value":"", "label" : "Grease Pump (where Applicable)",       "itemType" : "",  "itemComments" : "" },
                    { "value":"", "label" : "Check Vessel and Pipework for leaks",  "itemType" : "",  "itemComments" : "" },
                    { "value":"", "label" : "Paint Pipework as required",           "itemType" : "",  "itemComments" : "" },
                    { "value":"", "label" : "Is Signage displayed and legible",     "itemType" : "",  "itemComments" : "" },
                    { "value":"", "label" : "Check Hoses for Damage",               "itemType" : "",  "itemComments" : "" },
                    { "value":"", "label" : "Check Pressure Gauge",                 "itemType" : "",  "itemComments" : "" },
        ],
        section_6 : [ 
                     {"criteria":"Does site Confirm to COP",                                      "value"  : false },
                     {"criteria":"Site Free from Flammable Materials",                            "value"  : false },
                     {"criteria":"Site Free from Excessive Vegetation",                           "value"  : false },
                     {"criteria":"Base / Piers Satisfactory",                                     "value"  : false },
                     {"criteria":"Fencing / Bollards / Barrier satisfactory",                     "value"  : false },
                     {"criteria":"Tank Free of Corrosion / damage",                               "value"  : false },
                     {"criteria":"Hood Sound and Secure",                                         "value"  : false },
                     {"criteria":"PRV Rain Cap fitted",                                           "value"  : false },
                     {"criteria":"Fixed Liquid Level Gauge checked",                              "value"  : false },
                     {"criteria":"Valve Hand Whelel / Levers free",                               "value"  : false },
                     {"criteria":"Fittings and Pipework Examined visually",                       "value"  : false },
                     {"criteria":"Contents gauge in good repair / working",                       "value"  : false },
                     {"criteria":"Fire Fighting Facilities Availible",                            "value"  : false },
                     {"criteria":"Fill Valve Operation checked",                                  "value"  : false },
                     {"criteria":"PRV Vent Operation checked",                                    "value"  : false },
        ],
        section_7 : { comments:""}
        };
    }
};
</script>


<style scoped>




h1,h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.dl_expansion_panel_content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.header{
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: bold;
}

input[type=text] {
  width: 100%;
  padding: 8px 20px;
  margin: 2px 0;
  box-sizing: border-box;
  border: 3px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

input[type=text]:focus {
  border: 3px solid #555;
}

div.v-text-field__details {
  display: none;
}
</style>
           
           